<template>
  <div class="page_search">
	<div class="warp">
	  <div class="container">
		<div class="row">
		  <div class="col-12">
			<div class="card_result_search">
			  <div class="title">搜索结果</div>
							<!-- 商品搜索结果 -->
			  <list_result_search
				:list="result_goods"
				title="商品"
				source_table="goods"
			  ></list_result_search>

							<!-- 文章搜索结果 -->
			  <list_result_search
				:list="result_article"
				title="文章"
				source_table="article"
			  ></list_result_search>

							<!-- 论坛搜索结果 -->
			  <list_result_search
				:list="result_forum"
				title="论坛"
				source_table="forum"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/user_registration/list', 'get')"
				:list="result_user_number"
				title="用户编号"
				source_table="user_number"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/room_management/list', 'get')"
				:list="result_room_number"
				title="房号"
				source_table="room_number"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/room_management/list', 'get')"
				:list="result_type"
				title="类型"
				source_table="type"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/room_management/list', 'get')"
				:list="result_use_status"
				title="使用状态"
				source_table="use_status"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/technician/list', 'get')"
				:list="result_title"
				title="职称"
				source_table="title"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/technician/list', 'get')"
				:list="result_state"
				title="状态"
				source_table="state"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/technician/list', 'get')"
				:list="result_employee_number"
				title="职工编号"
				source_table="employee_number"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/commodity_management/list', 'get')"
				:list="result_trade_name"
				title="商品名称"
				source_table="trade_name"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/commodity_management/list', 'get')"
				:list="result_category"
				title="类别"
				source_table="category"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/i_want_to_make_a_reservation/list', 'get')"
				:list="result_room_number"
				title="房号"
				source_table="room_number"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/i_want_to_make_a_reservation/list', 'get')"
				:list="result_type"
				title="类型"
				source_table="type"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/i_want_to_make_a_reservation/list', 'get')"
				:list="result_user_number"
				title="用户编号"
				source_table="user_number"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/notification_management/list', 'get')"
				:list="result_notification_serial_number"
				title="通知序号"
				source_table="notification_serial_number"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/notification_management/list', 'get')"
				:list="result_date"
				title="日期"
				source_table="date"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/notification_management/list', 'get')"
				:list="result_type"
				title="类型"
				source_table="type"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/notification_management/list', 'get')"
				:list="result_employee_number"
				title="职工编号"
				source_table="employee_number"
			  ></list_result_search>
			  <list_result_search
								v-if="$check_action('/classification_information/list', 'get')"
				:list="result_category"
				title="类别"
				source_table="category"
			  ></list_result_search>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </div>
</template>

<script>
import mixin from "../../mixins/page.js";
import list_result_search from "../../components/diy/list_result_search.vue";

export default {
  mixins: [mixin],
  data() {
	return {
	  "query": {
		word: "",
	  },
	  "result_goods": [],
	  "result_article": [],
	  "result_forum": [],
			"result_user_number":[],
			"result_room_number":[],
			"result_type":[],
			"result_use_status":[],
			"result_title":[],
			"result_state":[],
			"result_employee_number":[],
			"result_trade_name":[],
			"result_category":[],
			"result_room_number":[],
			"result_type":[],
			"result_user_number":[],
			"result_notification_serial_number":[],
			"result_date":[],
			"result_type":[],
			"result_employee_number":[],
			"result_category":[],
	};
  },
  methods: {
	/**
	 * 获取商品
	 */
	get_goods() {
	  this.$get("~/api/goods/get_list?like=0", { page: 1, size: 10, title: this.query.word }, (json) => {
		if (json.result) {
		  this.result_goods = json.result.list;
		}
	  });
	},
	/**
	 * 获取文章
	 */
	get_article() {
	  this.$get("~/api/article/get_list?like=0", { page: 1, size: 10, title: this.query.word }, (json) => {
		if (json.result) {
		  this.result_article = json.result.list;
		}
	  });
	},
	/**
	 * 获取论坛
	 */
	get_forum() {
	  this.$get("~/api/forum/get_list?like=0", { page: 1, size: 10, title: this.query.word }, (json) => {
		if (json.result) {
		  this.result_forum = json.result.list;
		}
	  });
	},
	/**
	 * 获取user_number
	 */
	get_user_number(){
		this.$get("~/api/user_registration/get_list?like=0", { page: 1, size: 10, "user_number": this.query.word }, (json) => {
		  if (json.result) {
	  var result_user_number = json.result.list;
				result_user_number.map(o => o.title = o['user_number'])
	  this.result_user_number = result_user_number
		  }
		});
	},
	/**
	 * 获取room_number
	 */
	get_room_number(){
		this.$get("~/api/room_management/get_list?like=0", { page: 1, size: 10, "room_number": this.query.word }, (json) => {
		  if (json.result) {
	  var result_room_number = json.result.list;
				result_room_number.map(o => o.title = o['room_number'])
	  this.result_room_number = result_room_number
		  }
		});
	},
	/**
	 * 获取type
	 */
	get_type(){
		this.$get("~/api/room_management/get_list?like=0", { page: 1, size: 10, "type": this.query.word }, (json) => {
		  if (json.result) {
	  var result_type = json.result.list;
				result_type.map(o => o.title = o['type'])
	  this.result_type = result_type
		  }
		});
	},
	/**
	 * 获取use_status
	 */
	get_use_status(){
		this.$get("~/api/room_management/get_list?like=0", { page: 1, size: 10, "use_status": this.query.word }, (json) => {
		  if (json.result) {
	  var result_use_status = json.result.list;
				result_use_status.map(o => o.title = o['use_status'])
	  this.result_use_status = result_use_status
		  }
		});
	},
	/**
	 * 获取title
	 */
	get_title(){
		this.$get("~/api/technician/get_list?like=0", { page: 1, size: 10, "title": this.query.word }, (json) => {
		  if (json.result) {
	  var result_title = json.result.list;
				result_title.map(o => o.title = o['title'])
	  this.result_title = result_title
		  }
		});
	},
	/**
	 * 获取state
	 */
	get_state(){
		this.$get("~/api/technician/get_list?like=0", { page: 1, size: 10, "state": this.query.word }, (json) => {
		  if (json.result) {
	  var result_state = json.result.list;
				result_state.map(o => o.title = o['state'])
	  this.result_state = result_state
		  }
		});
	},
	/**
	 * 获取employee_number
	 */
	get_employee_number(){
		this.$get("~/api/technician/get_list?like=0", { page: 1, size: 10, "employee_number": this.query.word }, (json) => {
		  if (json.result) {
	  var result_employee_number = json.result.list;
				result_employee_number.map(o => o.title = o['employee_number'])
	  this.result_employee_number = result_employee_number
		  }
		});
	},
	/**
	 * 获取trade_name
	 */
	get_trade_name(){
		this.$get("~/api/commodity_management/get_list?like=0", { page: 1, size: 10, "trade_name": this.query.word }, (json) => {
		  if (json.result) {
	  var result_trade_name = json.result.list;
				result_trade_name.map(o => o.title = o['trade_name'])
	  this.result_trade_name = result_trade_name
		  }
		});
	},
	/**
	 * 获取category
	 */
	get_category(){
		this.$get("~/api/commodity_management/get_list?like=0", { page: 1, size: 10, "category": this.query.word }, (json) => {
		  if (json.result) {
	  var result_category = json.result.list;
				result_category.map(o => o.title = o['category'])
	  this.result_category = result_category
		  }
		});
	},
	/**
	 * 获取room_number
	 */
	get_room_number(){
		this.$get("~/api/i_want_to_make_a_reservation/get_list?like=0", { page: 1, size: 10, "room_number": this.query.word }, (json) => {
		  if (json.result) {
	  var result_room_number = json.result.list;
				result_room_number.map(o => o.title = o['room_number'])
	  this.result_room_number = result_room_number
		  }
		});
	},
	/**
	 * 获取type
	 */
	get_type(){
		this.$get("~/api/i_want_to_make_a_reservation/get_list?like=0", { page: 1, size: 10, "type": this.query.word }, (json) => {
		  if (json.result) {
	  var result_type = json.result.list;
				result_type.map(o => o.title = o['type'])
	  this.result_type = result_type
		  }
		});
	},
	/**
	 * 获取user_number
	 */
	get_user_number(){
		this.$get("~/api/i_want_to_make_a_reservation/get_list?like=0", { page: 1, size: 10, "user_number": this.query.word }, (json) => {
		  if (json.result) {
	  var result_user_number = json.result.list;
				result_user_number.map(o => o.title = o['user_number'])
	  this.result_user_number = result_user_number
		  }
		});
	},
	/**
	 * 获取notification_serial_number
	 */
	get_notification_serial_number(){
		this.$get("~/api/notification_management/get_list?like=0", { page: 1, size: 10, "notification_serial_number": this.query.word }, (json) => {
		  if (json.result) {
	  var result_notification_serial_number = json.result.list;
				result_notification_serial_number.map(o => o.title = o['notification_serial_number'])
	  this.result_notification_serial_number = result_notification_serial_number
		  }
		});
	},
	/**
	 * 获取date
	 */
	get_date(){
		this.$get("~/api/notification_management/get_list?like=0", { page: 1, size: 10, "date": this.query.word }, (json) => {
		  if (json.result) {
	  var result_date = json.result.list;
				result_date.map(o => o.title = o['date'])
	  this.result_date = result_date
		  }
		});
	},
	/**
	 * 获取type
	 */
	get_type(){
		this.$get("~/api/notification_management/get_list?like=0", { page: 1, size: 10, "type": this.query.word }, (json) => {
		  if (json.result) {
	  var result_type = json.result.list;
				result_type.map(o => o.title = o['type'])
	  this.result_type = result_type
		  }
		});
	},
	/**
	 * 获取employee_number
	 */
	get_employee_number(){
		this.$get("~/api/notification_management/get_list?like=0", { page: 1, size: 10, "employee_number": this.query.word }, (json) => {
		  if (json.result) {
	  var result_employee_number = json.result.list;
				result_employee_number.map(o => o.title = o['employee_number'])
	  this.result_employee_number = result_employee_number
		  }
		});
	},
	/**
	 * 获取category
	 */
	get_category(){
		this.$get("~/api/classification_information/get_list?like=0", { page: 1, size: 10, "category": this.query.word }, (json) => {
		  if (json.result) {
	  var result_category = json.result.list;
				result_category.map(o => o.title = o['category'])
	  this.result_category = result_category
		  }
		});
	},

  },
  components: { list_result_search },
	created(){
    this.query.word = this.$route.query.word || "";
  },
  mounted() {
	this.get_goods();
	this.get_article();
	this.get_forum();
		this.get_user_number();
		this.get_room_number();
		this.get_type();
		this.get_use_status();
		this.get_title();
		this.get_state();
		this.get_employee_number();
		this.get_trade_name();
		this.get_category();
		this.get_room_number();
		this.get_type();
		this.get_user_number();
		this.get_notification_serial_number();
		this.get_date();
		this.get_type();
		this.get_employee_number();
		this.get_category();
  },
  watch: {
	$route() {
	  $.push(this.query, this.$route.query);
	  this.get_goods();
	  this.get_article();
	  this.get_forum();
	  this.get_user_number();
	  this.get_room_number();
	  this.get_type();
	  this.get_use_status();
	  this.get_title();
	  this.get_state();
	  this.get_employee_number();
	  this.get_trade_name();
	  this.get_category();
	  this.get_room_number();
	  this.get_type();
	  this.get_user_number();
	  this.get_notification_serial_number();
	  this.get_date();
	  this.get_type();
	  this.get_employee_number();
	  this.get_category();
	},
  },
};
</script>

<style scoped>
.card_search {
  text-align: center;
}
.card_result_search>.title {
  text-align: center;
  padding: 10px 0;
}
</style>
